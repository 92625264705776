import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ScreenLoaderComponent from "src/Components/ScreenLoader";
import { GET_VOUCHERS_BRAND_BY_ID } from "src/Redux/actions/voucherBrands";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import DeleteModal from "./deleteModal";

export default function VoucherBrandDetailsLayout() {
  const dispatch = useDispatch();
  const { ID } = useParams();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [voucherId, setVoucherId] = useState("");

  const handleOpenModal = (id) => {
    setVoucherId(id);
    setDeleteModalOpen(true);
  };

  const voucherBrandInfo = useSelector(
    (state) => state?.voucherBrandReducer?.voucherBrandInfo
  );

  const isBrandLoading = useSelector(
    (state) => state?.voucherBrandReducer?.isBrandLoading
  );

  useEffect(() => {
    dispatch(GET_VOUCHERS_BRAND_BY_ID(ID));
  }, [dispatch, ID]);

  return isBrandLoading ? (
    <ScreenLoaderComponent />
  ) : (
    <div>
      <div>
        {deleteModalOpen && (
          <DeleteModal
            onClose={setDeleteModalOpen}
            voucherBrandID={ID}
            voucherId={voucherId}
          />
        )}
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-bold text-gray-900">
              {voucherBrandInfo?.name}
            </h1>
          </div>
          <button
            onClick={() => navigate(`/voucher-brands/${ID}/voucher/new`)}
            className="text-white bg-indigo-500 p-2 rounded-lg"
          >
            Add Voucher
          </button>
        </div>
        <div className="flex mt-8">
          <img
            src={voucherBrandInfo?.logo}
            alt="brandLogo"
            className="h-1/3 w-1/3 rounded-md"
          />
          <div className="ml-2">
            <h1 className="text-3xl font-bold text-gray-900">
              Instructions for user:
            </h1>
            <span className="test-base">{voucherBrandInfo?.description}</span>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Product Code
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {voucherBrandInfo?.vouchers?.map((voucher) => (
                    <tr key={voucher.uid}>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="font-medium text-gray-900">
                          {voucher?.product_code}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="mt-1 text-gray-500">
                          {voucher?.description}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{voucher?.type}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          PHP {voucher?.price}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-900">
                        <button className="p-2" onClick={() => handleOpenModal(voucher.uid)}>
                          <TrashIcon height={30} />
                          Delete
                        </button>
                        <button className="p-2">
                          <PencilSquareIcon
                            height={30}
                            onClick={() =>
                              navigate(
                                `/voucher-brands/${ID}/voucher/${voucher.uid}/edit`
                              )
                            }
                          />
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
