import HeaderWithSideNav from "src/Components/HeaderWithSideNav";
import BookingInfoLayout from "src/Layouts/BookingInfo";

const BookingInfoPage = () => {
  return (
    <>
      <div>
        <HeaderWithSideNav />
        <main className="pl-72">
          <div>
            <BookingInfoLayout />
          </div>
        </main>
      </div>
    </>
  );
};

export default BookingInfoPage;
