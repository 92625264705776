import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import ScrollToTop from "./scrollToTop";
import {
  // HomePage,
  LoginPage,
  BookingsPage,
  VoucherBrandsPage,
  VoucherBrandDetailsPage,
  VoucherFormPage,
  // BookingInfoPage
} from "../View";

const AllRoutes = () => (
  <Router>
    <ScrollToTop>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<BookingsPage />} />
          <Route path="/voucher-brands" element={<VoucherBrandsPage />} />
          <Route path="/voucher-brands/:ID" element={<VoucherBrandDetailsPage />} />
          <Route path="/voucher-brands/:ID/voucher/:voucherID/edit" element={<VoucherFormPage />} />
          <Route path="/voucher-brands/:ID/voucher/new" element={<VoucherFormPage />} />
          {/* <Route path="/bookings" element={<BookingsPage />} />
          <Route path="/bookings/:ID" element={<BookingInfoPage />} /> */}
        </Route>
      </Routes>
    </ScrollToTop>
  </Router>
);

export default AllRoutes;
