import HeaderWithSideNav from "src/Components/HeaderWithSideNav";
import VoucherBrandDetailsLayout from "src/Layouts/VoucherBrandDetails";

const VoucherBrandDetailsPage = () => {
  return (
    <div>
      <HeaderWithSideNav />
      <main className="pl-72">
        <div>
          <VoucherBrandDetailsLayout />
        </div>
      </main>
    </div>
  );
};

export default VoucherBrandDetailsPage;
