// **  Initial State
const initialState = {
  voucher: {
    product_code: "",
    description: "",
    currency: "",
    price: "",  
  },
  isVoucherLoading: false,
};

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VOUCHERS_BY_ID":
      return {
        ...state,
        voucher: action?.data,
      };
    case "IS_VOUHCER_LOADING":
      return {
        ...state,
        isVoucherLoading: action?.data,
      }
    default:
      return state;
  }
};

export default voucherReducer;
