// ** Redux Imports
import {combineReducers} from 'redux';
import authReducer from './auth';
import bookingReducer from './bookings';
import voucherBrandReducer from './voucherBrands';
import voucherReducer from './vouchers';

const rootReducer = combineReducers({
	authReducer,
	bookingReducer,
	voucherBrandReducer,
	voucherReducer
})

export default rootReducer
