import HeaderWithSideNav from "src/Components/HeaderWithSideNav";
import VoucherBrandsLayout from "src/Layouts/VoucherBrands";

const VoucherBrandsPage = () => {
  return (
    <div>
      <HeaderWithSideNav />
      <main className="pl-72">
        <div>
          <VoucherBrandsLayout />
        </div>
      </main>
    </div>
  );
};

export default VoucherBrandsPage;
