import HeaderWithSideNav from "src/Components/HeaderWithSideNav";
import BookingsPageLayout from "src/Layouts/Bookings";

const BookingsPage = () => {
  return (
    <div>
      <HeaderWithSideNav />
      <main className="pl-72">
        <div>
          <BookingsPageLayout />
        </div>
      </main>
    </div>
  );
};

export default BookingsPage;
