import HomeLayout from "src/Layouts/Home";
import HeaderWithSideNav from "src/Components/HeaderWithSideNav";

const HomePage = () => {
  return (
    <div>
      <HeaderWithSideNav />
      <main className="pl-72">
        <div>
          <HomeLayout />
        </div>
      </main>
    </div>
  );
};

export default HomePage;
