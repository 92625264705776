import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScreenLoaderComponent from "src/Components/ScreenLoader";
import { GET_ALL_BOOKINGS } from "src/Redux/actions/bookings";

export default function BookingsPageLayout() {
  const dispatch = useDispatch();
  const bookings = useSelector((state) => state?.bookingReducer?.bookings);

  const isBookingHistoyLoading = useSelector(
    (state) => state?.bookingReducer?.isBookingHistoyLoading
  );

  useEffect(() => {
    dispatch(GET_ALL_BOOKINGS());
  }, [dispatch]);

  return isBookingHistoyLoading ? (
    <ScreenLoaderComponent />
  ) : (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Bookings
          </h1>
          {/* <p className="mt-2 text-sm text-gray-700">
            A list of all the booki the users in your account including their name, title,
            email and role.
          </p> */}
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Icon
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Booking ID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    User Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Transaction ID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Transaction Time
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Product Info
                  </th>
                  
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Payment Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Transaction Time (Swif)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    User Account Input
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Delivery Status (Swif)
                  </th>
                  {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">View</span>
                  </th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {bookings?.map((booking) => (
                  <tr key={booking.uid}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img
                            className="h-11 w-11 rounded-full"
                            src={booking?.voucher?.brand?.logo}
                            alt=""
                          />
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">
                        {booking?.uid}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">
                        {booking?.userDetails?.name}
                      </div>
                      <div className="mt-1 text-gray-500">
                        {booking?.userDetails?.email}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">
                        {booking?.transaction_uid}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="font-medium text-gray-900">
                        Created at: {booking?.created_at}
                      </div>
                      <div className="mt-1 text-gray-500">
                        Last updated at: {booking?.updated_at}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="font-medium text-gray-900">
                        {booking?.voucher?.brand?.name}
                      </div>
                      <div className="mt-1 text-gray-500">
                        {booking?.voucher?.description}
                      </div>
                      <div className="mt-1 text-gray-500">
                        Product code: {booking?.voucher?.product_code}
                      </div>
                    </td>
                    
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">
                        PHP {booking?.amount}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {booking?.status === "FAILED" && (
                        <div className="text-rose-600 py-6 pr-8 sm:table-cell">
                          {booking?.status}
                        </div>
                      )}
                      {["PAYMENT_COMPLETE", "COMPLETE"].includes(
                        booking?.status
                      ) && (
                        <div className="text-green-600 py-6 pr-8 sm:table-cell">
                          COMPLETE
                        </div>
                      )}
                      {booking?.status === "PENDING" && (
                        <div className="text-yellow-600 py-6 pr-8 sm:table-cell">
                          {booking?.status}
                        </div>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="font-medium text-gray-900">
                        Created at: {booking?.created_at}
                      </div>
                      <div className="mt-1 text-gray-500">
                        Last updated at: {booking?.updated_at}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">
                        {booking?.account_number}
                      </div>
                      {/* <div className="text-gray-900">
                        Client ID: {booking?.account_number?.clientid}
                      </div>
                      <div className="mt-1 text-gray-500">
                        Client Server ID: {booking?.account_number?.clientserverid && booking?.account_number?.clientserverid}
                      </div>
                      <div className="mt-1 text-gray-500">
                        Client Sub ID: {booking?.account_number?.clientsubid && booking?.account_number?.clientsubid}
                      </div>
                      <div className="mt-1 text-gray-500">
                        Username: {booking?.account_number?.clientusername && booking?.account_number?.clientusername}
                      </div> */}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {booking?.status === "FAILED" && (
                        <div className="text-rose-600 py-6 pr-8 sm:table-cell">
                          {booking?.status}
                        </div>
                      )}
                      {["PAYMENT_COMPLETE", "PENDING"].includes(
                        booking?.status
                      ) && (
                        <div className="text-yellow-600 py-6 pr-8 sm:table-cell">
                          PENDING
                        </div>
                      )}
                      {booking?.status === "COMPLETE" && (
                        <div className="text-green-600 py-6 pr-8 sm:table-cell">
                          {booking?.status}
                        </div>
                      )}
                    </td>
                    {/* <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a href={`/bookings/${booking.uid}`} className="text-indigo-600 hover:text-indigo-900">
                        View
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
