import { auth, googleProvider, signInWithPopup } from "src/firebase";
import { useDispatch } from "react-redux";
import { HANDLE_ADMIN_LOGIN, GET_ADMIN_PROFILE } from "src/Redux/actions/auth";
import { useNavigate } from "react-router-dom";

export default function LoginLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user.providerData[0];
      const resData = await dispatch(HANDLE_ADMIN_LOGIN(user));
      if (!resData.data.error) {
        localStorage.setItem("token", JSON.stringify(resData?.data?.token));
        dispatch(GET_ADMIN_PROFILE());
        navigate("/");
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 pb-32 pt-16">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-10 w-auto"
                src="/amp-creds-logo.png"
                alt="Ampverse"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>
          </div>
          <div className="py-10">
            <button
              onClick={handleLogin}
              className="flex w-full items-center justify-center gap-3 border-solid border border-slate-300 rounded-lg bg-white px-3 py-1.5 text-black focus-visible:outline transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#FFC107"
                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
                <path
                  fill="#FF3D00"
                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                ></path>
                <path
                  fill="#4CAF50"
                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                ></path>
                <path
                  fill="#1976D2"
                  d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
              </svg>
              <span className="text-sm font-semibold leading-6">
                Login With Google
              </span>
            </button>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/IMG_9237.JPG"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
