import { MakeProtectedApiCall } from "../../../Services/api";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getHeadersWithAuth = () => {
  return {
    "authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    "content-type": "application/json"
  }
}

export const GET_VOUCHERS_BY_ID = (voucherId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_VOUHCER_LOADING", data: true });
      const url = `${baseUrl}/mall/vouchers/${voucherId}`;
      const res = await MakeProtectedApiCall(
        url,
        "GET",
        getHeadersWithAuth()
      );
      dispatch({ type: "GET_VOUCHERS_BY_ID", data: res?.data });
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    } finally {
      dispatch({ type: "IS_VOUHCER_LOADING", data: false });
    }
  };
};

export const UPDATE_VOUCHER = (data) => {
  return async () => {
    try {
      const url = `${baseUrl}/mall/vouchers`;
      const res = await MakeProtectedApiCall(
        url,
        "PUT",
        getHeadersWithAuth(),
        {
          uid: data.uid,
          price: parseFloat(data.price),
          product_code: data.product_code,
          type: data.type,
          description: data.description,
        },
      );
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
};

export const CREATE_VOUCHER = (data) => {
  return async () => {
    try {
      const url = `${baseUrl}/mall/vouchers`;
      const res = await MakeProtectedApiCall(
        url,
        "POST",
        getHeadersWithAuth(),
        {
          price: parseFloat(data.price),
          product_code: data.product_code,
          type: data.type,
          description: data.description,
          currency: data.currency,
          voucher_brand_uid: data.voucher_brand_uid
        },
      );
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
};

export const DELETE_VOUCHER = (id) => {
  return async () => {
    try {
      const url = `${baseUrl}/mall/vouchers/${id}`;
      const res = await MakeProtectedApiCall(
        url,
        "DELETE",
        getHeadersWithAuth(),
      );
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
};
