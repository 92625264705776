import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GET_VOUCHERS_BY_ID, UPDATE_VOUCHER, CREATE_VOUCHER } from "src/Redux/actions/vouchers";

export default function VoucherFromLayout() {
  const { ID, voucherID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const voucherDetails = useSelector((state) => state?.voucherReducer?.voucher);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: voucherDetails });

  useEffect(() => {
    if (voucherID) {
      dispatch(GET_VOUCHERS_BY_ID(voucherID));
    }
  }, [dispatch, voucherID]);

  useEffect(() => {
    reset(voucherDetails);
  }, [voucherDetails, reset]);

  const onSubmit = async (data) => {
    if (voucherID) {
      const res = await dispatch(UPDATE_VOUCHER(data));
      if (res.data.uid) {
        navigate(`/voucher-brands/${ID}`);
      }
    } else {
      data.voucher_brand_uid = ID;
      const res = await dispatch(CREATE_VOUCHER(data));
      if (res.data.uid) {
        navigate(`/voucher-brands/${ID}`);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="p-2 grid grid-cols-2 gap-4">
        <div>
          <label
            htmlFor="product_code"
            className="block text-sm font-medium text-gray-700"
          >
            Product Code
          </label>
          <input
            type="text"
            placeholder="Product Code"
            id="product_code"
            name="product_code"
            className="p-1 border border-gray-600 rounded-lg w-full"
            {...register("product_code", {
              required: ID ? false : true,
            })}
          />
          {errors.hasOwnProperty("product_code") && (
            <span className="text-rose-600 text-sm">
              Product Code is Required
            </span>
          )}
        </div>

        <div>
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Voucher Type
          </label>
          <select
            className="p-1 border border-gray-600 rounded-lg w-full"
            name="type"
            {...register("type", { required: true })}
          >
            <option value="">Select Voucher Type</option>
            <option value="GAMES-TOP-UP">Games Top Up</option>
            <option value="PIN-RELOAD">Pin Reload</option>
            <option value="E-RELOAD">E Reload</option>
          </select>
          {errors.hasOwnProperty("type") && (
            <span className="text-rose-600 text-sm">
              Type is Required
            </span>
          )}
        </div>

        <div className="col-span-2">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <input
            type="text"
            placeholder="Description"
            id="description"
            name="description"
            className="p-1 border border-gray-600 rounded-lg w-full"
            {...register("description", {
              required: ID ? false : true,
            })}
          />
          {errors.hasOwnProperty("Description") && (
            <span className="text-rose-600 text-sm">
              Description is Required
            </span>
          )}
        </div>

        <div>
          <label
            htmlFor="currency"
            className="block text-sm font-medium text-gray-700"
          >
            Currency
          </label>
          <input
            type="text"
            placeholder="Currency"
            id="currency"
            name="currency"
            className="p-1 border border-gray-600 rounded-lg w-full"
            {...register("currency", {
              required: ID ? false : true,
            })}
          />
          {errors.hasOwnProperty("currency") && (
            <span className="text-rose-600 text-sm">
              Currency is Required
            </span>
          )}
        </div>

        <div>
          <label
            htmlFor="price"
            className="block text-sm font-medium text-gray-700"
          >
            Price
          </label>
          <input
            type="text"
            placeholder="Price"
            id="price"
            name="price"
            className="p-1 border border-gray-600 rounded-lg w-full"
            {...register("price", {
              required: ID ? false : true,
            })}
          />
          {errors.hasOwnProperty("price") && (
            <span className="text-rose-600 text-sm">
              Price is Required
            </span>
          )}
        </div>
      </div>
      <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Submit
        </button>
        <button
          type="button"
          onClick={() => navigate(`/voucher-brands/${ID}`)}
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Back
        </button>
      </div>
    </form>
  );
}
