import { MakeProtectedApiCall } from "../../../Services/api";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getHeadersWithoutAuth = () => {
  return {
    "Content-Type": "application/json",
  };
};

const getHeadersWithAuth = () => {
  return {
    "authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    "content-type": "application/json"
  }
}

export const HANDLE_ADMIN_LOGIN = (userData) => {
  return async () => {
    try {
      const url = `${baseUrl}/admin/login`;
      const res = await MakeProtectedApiCall(
        url,
        "POST",
        getHeadersWithoutAuth(),
        {
          name: userData.displayName,
          phone_number: userData.phoneNumber,
          email: userData.email,
          avatar: userData.photoURL,
          social_id: userData.uid,
        },
      );
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
};

export const GET_ADMIN_PROFILE = () => {
  return async (dispatch) => {
    try {
      const url = `${baseUrl}/users/profile`;
      const res = await MakeProtectedApiCall(
        url,
        "GET",
        getHeadersWithAuth()
      );
      dispatch({ type: "GET_ADMIN_PROFILE", data: res?.data });
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
}

export const HANDLE_ADMIN_LOGOUT = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "HANDLE_ADMIN_LOGOUT"});
      localStorage.clear();
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
};