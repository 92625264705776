// **  Initial State
const initialState = {
  bookings: null,
  bookingInfo: null,
  isBookingHistoyLoading: false,
  isBookingInfoLoading: false,
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_BOOKINGS":
      return {
        ...state,
        bookings: action?.data,
      };
    case "GET_BOOKING_BY_ID":
      return {
        ...state,
        bookingInfo: action?.data,
      };
    case "IS_ALL_BOOKINGS_LOADING": 
      return {
        ...state,
        isBookingHistoyLoading: action.data,
      };
    case "IS_BOOKING_INFO_LOADING": 
      return {
        ...state,
        isBookingInfoLoading: action.data,
      }
    default:
      return state;
  }
};

export default bookingReducer;
